.bg-gradient-1 {
  background: linear-gradient(90deg, #2e8f66 66.28%, #0e3f19 100%);
}

.gradient-1 {
  background: linear-gradient(180deg, rgba(152, 223, 245, 0) 0%, #a0e7fd 60.71%);
}

.no-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.autocomplete-ui {
  width: 358px;
  position: relative;

  input {
    outline: none;
    width: 100%;

    &::placeholder {
      color: rgba(60, 60, 67, 0.6);
    }
  }

  @media screen and (max-width: 1155px) {
    width: 100%;
  }
}

.autocomplete-dropdown {
  position: absolute;
  left: 0;
  top: 48px;
  width: 100%;
}

.search-trend {
  width: 100% !important;
}

.product-h-image {
  width: 70px;
  height: 50px;
}

.custom-border-t-1 {
  border-top: 1px solid #ececec;
}

.clear-button {
  right: 16px;
  top: 16px;

  [dir='rtl'] & {
    right: unset;
    left: 16px;
  }
}

.apollo-active {
  color: #35b050;
  padding: 4px 8px;
  // width: 70px;
  height: 27px;
  background: #ffffff;
  border-radius: 8px;
}

.route-loading-indicator {
  display: block;
  width: 100%;
  height: 3px;
  background: red;
  position: fixed;
  z-index: 9999999999;
  top: 0;
  opacity: 0.9;
  background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
  background-size: 900% 900%;
  animation: rainbow 3s ease infinite;
}

h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: #ffffff;
}

.sticky-view {
  display: none;
}
